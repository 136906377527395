import React, { Component } from "react";
import { updatePasscode } from "../training-services/updatePasscode";

class ChangePasscode extends Component {
  state = {
    username: this.props.username,
    userid: this.props.userid,
    editPasscodeID: 1,
    updatePasscode_value: "",
  };

  handleUpdatePasscode_Value = (e) => {
    this.setState({ updatePasscode_value: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-dark settings-menu">
          <div className="p-3">
            <h2 className="text-light mb-5">Pin Eingang bearbeiten</h2>
            <label className="form-label text-light fw-bold fs-5">
              Neuen Pin eingeben:
            </label>
            <input
              type="number"
              className="form-control"
              value={this.state.updatePasscode_value}
              onChange={this.handleUpdatePasscode_Value}
            />
            <button
              className="btn btn-outline-light mt-3"
              onClick={() => {
                updatePasscode(this.state).then(
                  this.setState({
                    updatePasscode_value: "",
                  })
                );
              }}
            >
              Speichern
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePasscode;
