import baseURL from "./../../utils/API";
import axios from "axios";
import { toast } from "react-toastify";

export const updatePasscode = async (data) => {
  if (data.updatePasscode_value.toString().length < 6) {
    toast.error("Pin muss mindestens 6 Zahlen beinhalten!");
    return;
  } else {
    try {
      const response = await axios.post(
        baseURL + "/passcode/passcode-update.php",
        data
      );
      if (response.data.success === 1) {
        toast.success("Daten erfolgreich erfasst");
      } else if (response.data.status === 404) {
        toast.error("Keine Verbindung zum Server!");
      } else {
        toast.error("Etwas ist schiefgelaufen!");
      }
    } catch (error) {
      console.log(error);
    }
  }
};
